<template>
  <sb-page-content title="Vehicle Captures Inbox">
    <v-container class="pa-0">
      <v-toolbar dark
                 color="secondary"
                 class="mb-2 toolbar-rounded search-padding">
        <v-text-field v-model="search"
                      clearable
                      flat
                      solo
                      light
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                      @click:clear="clearSearch" />
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{on}">
              <div v-on="on">
                <v-select v-model="filterStatus"
                          :disabled="$wait.any"
                          flat
                          solo
                          light
                          hide-details
                          :items="serviceRequestStatuses"
                          label="Status" />
              </div>
            </template>
            <span>Status</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{on}">
              <v-btn icon
                     class="mx-3"
                     v-on="on"
                     @click="refreshList">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </template>
      </v-toolbar>

      <sb-list :items="vehicleCaptures"
               :loading="$wait.is('serviceRequestsLoading')"
               :page.sync="pagination.number"
               :items-per-page.sync="pagination.numberOfElements"
               :total-pages="pagination.totalPages"
               empty-message="No Vehicles Submitted"
               empty-sub-message="No vehicles available at this time or try relaxing your search parameters"
               list-item-component="SbVehicleListItem" />
    </v-container>
  </sb-page-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import SbList from '@/components/core/SbList';

import {
  VEHICLE_CAPTURE_STATUSES,
  SR_APPLICATION_TYPES,
} from '@/config/constants';

import Pagination from '@/models/Pagination';

export default {
  components: {
    SbList,
  },

  data() {
    return {
      search: '',
      filterStatus: '',
      pagination: new Pagination(),
      vehicleCaptures: [],
      tempData: [
        {
          vehicleUploadNumber: 1234,
          vehiclesToBeAdded: 45,
          requestNumber: 321,
          status: 'COMPLETE',
          loadDateTime: '2021-07-02 06:45',
          submissionDateTime: '2021-07-02 06:45',
        },
        {
          vehicleUploadNumber: 1234,
          vehiclesToBeAdded: 45,
          requestNumber: 321,
          status: 'SAVED',
          loadDateTime: '2021-07-02 06:45',
          submissionDateTime: '2021-07-02 06:45',
        },
        {
          vehicleUploadNumber: 1234,
          vehiclesToBeAdded: 45,
          requestNumber: 321,
          status: 'VALIDATION_FAILED',
          loadDateTime: '2021-07-02 06:45',
          submissionDateTime: '2021-07-02 06:45',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      serviceRequests: 'serviceRequests/serviceRequests',
    }),

    serviceRequestStatuses() {
      return Object.values(VEHICLE_CAPTURE_STATUSES).filter((x) => !x.hidden);
    },
  },

  watch: {
    filterStatus() {
      this.getServiceRequestsFiltered();
    },

    search(value) {
      // eslint-disable-next-line
      value && this.getServiceRequestsFiltered();
    },

    'pagination.number': {
      handler() {
        this.getServiceRequestsFiltered();
      },
    },

    'pagination.numberOfElements': {
      handler() {
        this.getServiceRequestsFiltered();
      },
    },
  },

  async created() {
    await this.getServiceRequestsFiltered();
  },

  beforeDestroy() {
    this.$store.commit('serviceRequests/serviceRequests', []);
  },

  methods: {
    ...mapActions('serviceRequests', ['getServiceRequests']),

    ...mapWaitingActions('serviceRequests', {
      getServiceRequests: 'serviceRequestsLoading',
    }),

    clearSearch() {
      this.search = '';
      this.getServiceRequestsFiltered();
    },

    async getServiceRequestsFiltered() {
      if (this.filterStatus === 'ALL') {
        this.filterStatus = '';
      } else if (this.filterStatus === 'COMPLETE') {
        this.filterStatus = 'SR_STATUS_COMPLETED';
      } else if (this.filterStatus === 'IN_PROGRESS') {
        this.filterStatus = 'SR_STATUS_IN_PROGRESS';
      } else if (this.filterStatus === 'SAVED') {
        this.filterStatus = 'SR_STATUS_SAVED';
      } else if (this.filterStatus === 'VALIDATION_FAILED') {
        this.filterStatus = 'SR_STATUS_VALIDATION_FAILED';
      }

      const result = await this.getServiceRequests({
        status: this.filterStatus,
        srType: SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_VEHICLE_CAPTURE,
        authUser: this.authUser,
        filterValue: this.search || '',
        page: this.pagination.number,
        pageSize: this.pagination.numberOfElements,
      });

      this.pagination = result.pagination;
      this.vehicleCaptures = this.serviceRequests.map((request) => ({
        vehicleCapture: request.vehicleCapture,
        requestNumber: request.serviceRequestNumber,
        applicationType: request.applicationType.value,
        loadDateTime: request.createdTimestamp,
        status: request.status.value,
        submissionDateTime: request.createdTimestamp,
        vehiclesToBeAdded: request.vehicleCapture.vehicles.length,

      }));
    },

    refreshList() {
      this.getServiceRequestsFiltered();
    },
  },
};
</script>
