<template>
  <v-data-iterator :items="items"
                   :items-per-page.sync="itemsPerPage"
                   :page="page"
                   :loading="loading"
                   sort-by="order"
                   hide-default-footer>
    <template #default="props">
      <v-row>
        <v-col v-for="(item, index) in props.items"
               :key="index"
               cols="12">
          <component :is="listItemComponent"
                     :item="item"
                     @item-click="$emit('item-click', item)" />
        </v-col>
      </v-row>
    </template>

    <template #no-data>
      <sb-empty-message :message="emptyMessage"
                        :sub-message="emptySubMessage" />
    </template>

    <template #loading>
      <v-row justify="center">
        <sb-loader class="mt-5" />
      </v-row>
    </template>

    <template v-if="!hidePagination"
              #footer>
      <sb-pagination :items-per-page="itemsPerPage"
                     :total-pages="totalPages"
                     :page="page"
                     @update-items-per-page="updateItemsPerPage"
                     @update-page="updatePage" />
    </template>
  </v-data-iterator>
</template>

<script>
import SbPagination from '@/components/core/SbPagination';
import paginationBase from '@/mixins/paginationBase';

export default {
  components: {
    SbWorkAuthorisationListItem: () => import('@/components/authorisation/SbWorkAuthorisationListItem'),
    SbServiceRequestListItem: () => import('@/components/serviceRequest/SbServiceRequestListItem'),
    SbServiceRequestTaskListItem: () => import('@/components/serviceRequest/SbServiceRequestTaskListItem'),
    SbFundAllocationListItem: () => import('@/components/fundAllocation/SbFundAllocationListItem'),
    SbVehicleListItem: () => import('@/components/vehicle/SbVehicleListItem'),
    SbPagination,
  },
  mixins: [paginationBase],
  props: {
    listItemComponent: {
      type: String,
      required: true,
    },
  },
};
</script>
